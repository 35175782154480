<template>
  <div>
    <div class="workdayShopD">
      <div class="workdayShopD_box">
        <div class="workdayShopD_di">
          <div>
            <!-- <img src="../../assets/img/din.png" alt=""> -->
            <!-- <h2>碳鱼烧烤（丰台分店）</h2> -->
          </div>
          <div>
            <a @click="xinZen">新增</a>
            <a @click="cancel_surface">删除</a>
            <a @click="amesssnd()">修改</a>
          </div>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" height="450"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="155"> </el-table-column>

        <el-table-column prop="serial" label="角色" width="230">
        </el-table-column>
        <el-table-column prop="bumen" label="工作日设置" width="320">
          <template slot-scope="scope">
            <div>
              <el-input disabled v-model="scope.row.value1" style=""></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="休息天数" width="300">
          <template slot-scope="scope">
            <div>
              <el-input disabled v-model="scope.row.day" placeholder="1" value="1"></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="entrepot" label="工作时间" width="695">
          <template slot-scope="scope">
            <el-time-picker disabled is-range v-model="scope.row.value2" range-separator="至" format="HH:mm"
              :start-placeholder="scope.row.kaishgi" :end-placeholder="scope.row.jieshu" placeholder="选择时间范围">
            </el-time-picker>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="tishi">提示： 若员工轮休采取轮休制，则设置周一到周日均为工作日</div>

    <el-drawer title="我是标题" :visible.sync="drawer" v-if="drawer"  :with-header="false">
      <div class="working_days">
        <h2><span></span> 工作日设置</h2>
        <div>
          <p><span>*</span>选择角色:</p>


          <el-cascader v-model="role_value" v-if="drawer" :options="role" placeholder="请选择角色" :props="defaultProps"></el-cascader>
           <!-- <el-cascader v-model="place_value" :options="place_options" :props="defaultProp" :show-all-levels="false">
                  </el-cascader> -->

        
          <!-- <el-select v-model="role_value" @change="role_valueCheang" placeholder="请选择">
                        <el-option
                        v-for="item in role"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select> -->
        </div>
        <div>
          <p><span>*</span>选择工作日:</p>

          <el-select v-model.trim="workday_value" @change="workday_valueCheang" multiple placeholder="请选择">
            <el-option v-for="item in workday" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>

          <p><span>*</span>休息天数: </p>
          <el-input v-model="days" placeholder="请输入"></el-input>
        </div>
        <div>

          <p><span>*</span>选择工作时间:</p>
          <el-time-picker is-range  format='HH:mm' value-format='HH:mm'   v-model="time_S"
            range-separator="至" :start-placeholder="kaishi" :end-placeholder="jieshuS"   placeholder="选择时间范围">
          </el-time-picker>
        </div>
        <el-button @click="New_Working()" type="danger" round v-if="bao">保存</el-button>
        <el-button @click="amend_Working()" type="danger" round v-if="xiu">修改</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
  import {
    query_workday_set_list,
    update_workday_set,
    delete_workday_set,
    insert_workday_set
  } from '@api/pulic'

  import {
    insert_role_group_info,
    query_role_group_info_list,
    delete_role_group_info,
    insert_role_info,
    query_dept_staff_tree_aim_not,
    set_staff_role_rel,
    query_user_list_of_role
  } from '../../api/store'

  export default {
    name: 'Workday',
    data() {
      return {

        bao: true,
        xiu: false,
        kaishi: '',
        jieshuS: '',
        tableData: [

        ],
        defaultProps: {
          multiple: true,
          children: 'role_list',
          label: 'role_name',
          value: 'role_id',
           emitPath:false

        },
        drawer: false,
        // 角色
        role: [],
        roleS: {}, // 角色
        role_value:[],
        role_value1:[], // 选择角色的value
        //  选择工作日
        workday_S: {},
        workday_value: '',
        workday: [{
          value: '1',
          label: '周一'
        }, {
          value: '2',
          label: '周二'
        }, {
          value: '3',
          label: '周三'
        }, {
          value: '4',
          label: '周四'
        }, {
          value: '5',
          label: '周五'
        }, {
          value: '6',
          label: '周六'
        }, {
          value: '7',
          label: '周日'
        }],
        // 休息天数
        days: '',
        // 选择时间
        time_S: null,
        time_sss: '',

        P_id: '',
        gongZr: '',

        sahngBang: [],
        list_set: '',
      }
    },
    created() {
      this.P_id = this.$route.params.sss

      sessionStorage.setItem('P_id', this.$route.params.sss)
      console.log(sessionStorage.getItem('P_id'))

      // 加载方案列表
      this.liebiao()
      this.getreolList()

    },
    watch: {
      drawer(val) {
        if (!val) {
          this.emit()
        }
      },

    },
    methods: {

      getreolList() {
        query_role_group_info_list({
          data: {
            ent_id: this.$store.state.ent_id,
            active: '1'
          }
        }).then(res => {
          if (res.code == '200') {
            console.log(res.body.data, '角色列表')
            for (const i in res.body.data) {
              res.body.data[i].role_name = res.body.data[i].role_group_name
               res.body.data[i].role_id = res.body.data[i].role_group_id
            }


            this.role = res.body.data

          }
        })
      },
      emit() {

 
        this.role_value = ''
        this.workday_value = []
        this.days = ''
        this.time_S =null
        this.drawer=false
      },
      xinZen() {
        this.drawer = true
        this.bao = true
        this.xiu = false
       this.kaishi = ''
       this.jieshuS = ''
      },

      amesssnd() {
        let self=this
        if (this.list_set.length != 1) {
          this.$message({
            message: '您请选择一个设置进行修改',
            type: 'waring'
          })
        } else {
          // console.log(this.list_set)
            // console.log(this.list_set[0].role_id)



          this.role_value = this.list_set[0].role_id.split(',')
          console.log(this.role_value);
          // this.$set(this.role_value,this.role_value)
          // this.role_value = this.list_set[0].set_idA //  ssssssssssssssssssssssssssssssssssssssssssssssssss 有问题
          this.workday_value = this.list_set[0].value1.split(',');
          this.days = this.list_set[0].days

          this.kaishi = this.list_set[0].kaishgi
          this.jieshuS = this.list_set[0].jieshu
          // }, 120)

          this.$nextTick(function () {
            // this.drawer = true
            this.bao = false
            this.xiu = true
          })
          setTimeout(() => {
               self.drawer = true
          }, 0);



        }



      },


      amend_Working() {
        let that=this
         let role_name = '';
        console.log(that.role_value)
        let arr = that.role_value.flat(Infinity)
        let arr2 = [];

        for (const i in arr) {
          if (arr[i] != undefined && arr[i][0] == 'J' || arr[i] != undefined && arr[i][0] == 'd') {
            arr2.push(arr[i])
          }
        }

        var arr3 = [];
        arr3 = that.role.flat(Infinity)
        var arr4 = [];

        for (const i in that.role) {
          if (that.role[i].role_list != undefined) {
            for (const j in that.role[i].role_list) {
              if (arr2.includes(that.role[i].role_list[j].role_id)) {
                arr4.push(that.role[i].role_list[j].role_name);
              }
            }
          }
        }



        let ss = this.list_set[0].set_idA

        if (!that.workday_value) {

          this.$message({
            message: '请选择工作日',
            type: 'error',

          })

          return

        }
        if (!that.role_value) {
          this.$message({
            message: '请选择角色',
            type: 'error',

          })
          return
        }
        if (!that.days) {
          this.$message({
            message: '请选择天数',
            type: 'error',

          })
          return

        }
        if (!that.time_S) {
          this.$message({
            message: '请选择时间',
            type: 'error',

          })
          return
        }

          update_workday_set({
            data: {
              ent_id: this.$store.state.ent_id, // 企业
              user_id:this.$store.state.user_id,
              plan_id: this.P_id, // 桌台
              role_id: arr2.join(','), //角色id
              set_id: ss,
              role_name: arr4.join(','), //角色名称
              workday: this.workday_value.toString(), //工作日，1-7，逗号分割
              days_rest: this.days, //每周休息天数
              time_start: JSON.stringify(this.time_S[0]), //上班时间
              time_end:  JSON.stringify(this.time_S[1])  //下班时间
            }
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.emit();
            this.$message({
            message: '修改成功',
            type: 'success',

          })
             this.liebiao()
            }
          })

      },

      handleSelectionChange(val) {
        this.list_set = val
      },

      // 删除列表
      cancel_surface() {



            console.log(this.list_set)
            
        let param = {
          ent_id: this.$store.state.ent_id,
          set_id: this.list_set.map(x => x.set_id).join(','),
          user_id:this.$store.state.user_id
        }
        delete_workday_set({
          data: param
        }).then(res => {
           this.$message({
            message: '删除成功',
            type: 'error',

          })
          this.liebiao()
        })
      },

      // 工作日   obj
      workday_valueCheang(vid) {
        console.log(vid);
        let obj = []
        this.workday.forEach(item => {
          vid.forEach(itema => {
            if (itema == item.value) {
              obj.push(item.label)
            }
          });
        });
        console.log(obj.toString())
        this.gongZr = obj.toString()
      },

      // 时间
      time_box(i) {
        // console.log(i)
        console.log(this.time_S)
        this.time_sss = this.time_S[0] + '-' + this.time_S[1]
        console.log(this.time_sss)
      },

      New_Working() {
        var that = this;

        if (!that.workday_value) {

          this.$message({
            message: '请选择工作日',
            type: 'error',

          })

          return

        }
        if (!that.role_value) {
          this.$message({
            message: '请选择角色',
            type: 'error',

          })
          return
        }
        if (!that.days) {
          this.$message({
            message: '请选择天数',
            type: 'error',

          })
          return

        }
        if (!that.time_S) {
          this.$message({
            message: '请选择时间',
            type: 'error',

          })
          return
        }

        let role_name = '';
        console.log(that.role_value)
        let arr = that.role_value.flat(Infinity)
        let arr2 = [];

        for (const i in arr) {
          if (arr[i] != undefined && arr[i][0] == 'J' || arr[i] != undefined && arr[i][0] == 'd') {
            arr2.push(arr[i])
          }
        }

        var arr3 = [];
        arr3 = that.role.flat(Infinity)
        var arr4 = [];

        for (const i in that.role) {
          if (that.role[i].role_list != undefined) {
            for (const j in that.role[i].role_list) {
              if (arr2.includes(that.role[i].role_list[j].role_id)) {
                arr4.push(that.role[i].role_list[j].role_name);
              }
            }
          }
        }
        if (arr2, arr4, this.gongZr, this.days, this.time_S[0], this.time_S[1]) {
          insert_workday_set({
            data: {
              ent_id: that.$store.state.ent_id, // 企业
              user_id:that.$store.state.user_id,
              plan_id: that.P_id, // 桌台
              role_id: arr2.join(','),
              role_name: arr4.join(','), //角色名称
              workday: that.gongZr, //工作日，1-7，逗号分割
              days_rest: that.days, //每周休息天数
              time_start:that.time_S[0], //上班时间
              time_end:that.time_S[1]//下班时间
            }
          }).then(res => {
            console.log('渲染方案列表', res)
            if (res.code == 200) {
              that.emit()
              that.drawer = false
            }
            this.liebiao()
          })
        } else {
          this.message({
            message: "流程未完成"
          })
        }

      },

      // 渲染列表
      liebiao() {
        query_workday_set_list({
          data: {
            ent_id: this.$store.state.ent_id,
            plan_id: this.P_id,
          }
        }).then(res => {
          console.log(res)
          let arr = res.body.data
          this.tableData = []
          arr.forEach(item => {
            let objS = {
              set_id:item.set_id,
              serial: item.role_name, // 角色
              days: item.days_rest, // 休息天数
              value1: item.workday,
              day: item.days_rest,
              kaishgi: item.time_start,
              jieshu: item.time_end,
              role_id: item.role_id
            }
            this.tableData.push(objS)
          })
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .el-main {

    background: #f2f6fc;
  }

  .workdayShopD {
    background: #fff;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;
    padding: 0 0 200px 0;
    box-sizing: border-box;
    border-radius: 16px;
    margin: 30px 0;

    .workdayShopD_box {
      .workdayShopD_di {
        display: flex;
        height: 80px;
        background: #fff;
        border-radius: 15px 15px 0px 0px;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        box-sizing: border-box;
        h2 {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: 600;
          color: #333333;
          line-height: 30px;
        }

        img {
          width: 23px;
          height: 30px;
          margin-right: 16px;
        }

        div {
          display: flex;

          a {
            width: 100px;
            height: 44px;
            border: 1px solid #FF0000;
            border-radius: 10px;
            display: block;
            font-size: 16px;
            color: #FF4141;
            line-height: 44px;
            text-align: center;
            margin-left: 20px;
          }
        }
      }
    }
  }

  .working_days {
    padding: 10px;

    h2 {
      position: relative;
      color: #333;
      font-size: 20px;
      font-weight: 400;
      padding-left: 12px;
      margin-top: 20px;
      margin-bottom: 40px;

      span {
        display: block;
        position: absolute;
        left: 0;
        top: -7px;
        width: 6px;
        height: 32px;
        background: #FF0000;
        border-radius: 3px;
      }
    }

    div {
      margin-bottom: 10px;
      display: flex;

      &>p {
        font-size: 16px;
        color: #333;
        width: 130px;
        text-align: right;
        position: relative;
        margin: 10px;

        span {
          color: #FF4141;
          font-size: 16px;
          margin-right: 4px;
        }
      }
    }
  }
</style>

<style>
  .workdayShopD .el-input.is-disabled .el-input__inner {
    color: black;
  }


  .workdayShopD .el-input__inner {
    color: black;


  }

  .el-range-editor.is-disabled input {
    color: black !important;
  }

  .workdayShopD .el-range-editor.is-disabled {
    color: black !important;
  }

  .workdayShopD .el-range-editor.is-disabled .el-range-separator {
    color: black !important;
  }
</style>